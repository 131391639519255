body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    /*overflow-y: auto;*/
    /*max-height: 100%;*/
    background-color: white;

}

#tv-channel {
    .ant-pro-checkcard-body {
        padding-top: 0 !important;
        margin-top: -24px;
    }
}


.movie-info .movie-dl {
    margin: 0
}

.movie-info .movie-dt,
.movie-info .movie-dd {
    display: inline
}

.movie-info .movie-dd {
    display: inline;
    margin: 0 10px -4px 2px
}
